gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

let isAminated = false;

if (isAminated && ScrollTrigger.isTouch !== 1) {

	ScrollSmoother.create({
		wrapper: '.ScrollSmoother-wrapper',
		content: '.t-records',
		smooth: 1.5,
		effects: true
	})


	const timeLine = gsap.timeline({ repeat: -1, repeatDelay: .5, yoyo: true })

	// Главный баннер
	timeLine.fromTo('.hero-title, .hero-subtitle, .hero-btn', { opacity: 1 }, {
		opacity: 0, y: -50,
		scaleX: 1.1, scaleY: 1.1,
		scrollTrigger: {
			trigger: '#rec585762633',
			start: '100',
			end: '820',
			scrub: true
		}
	})

	// START: О компании
	let itemsL = gsap.utils.toArray('.about-decor--left');

	itemsL.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, x: -100 }, {
			opacity: 1, x: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-100',
				scrub: true
			}
		})
	})

	let itemsR = gsap.utils.toArray('.about-decor--right');

	itemsR.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, x: 100 }, {
			opacity: 1, x: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	let itemsC = gsap.utils.toArray('.about-decor--center');

	itemsC.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	let textL = gsap.utils.toArray('.about-text--left');

	textL.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: -30 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	let textR = gsap.utils.toArray('.about-text--right');

	textR.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: -30 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	let textC = gsap.utils.toArray('.about-text--center');

	textC.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: -30 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	// END: О компании

	// Слайдер компании
	timeLine.fromTo('#rec586280415', { opacity: 0, y: +100 }, {
		opacity: 1, y: 0,
		scaleX: 1.1, scaleY: 1.1,
		scrollTrigger: {
			trigger: '#rec586280415',
			start: '-950',
			end: 'top',
			scrub: true
		}
	})

	// Элементы слайдера
	let slideCard = gsap.utils.toArray('#rec586282668 .tn-elem__5862826681683100362589, #rec586282668 .tn-elem__5862826681683100362592, #rec586282668 .tn-elem__5862826681683100362596');
	slideCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +30 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-900',
				end: '-800',
				scrub: true
			}
		})
	});

	// Программы обучения - линия
	timeLine.fromTo('#rec585851287', { opacity: 0, y: +200 }, {
		opacity: 1, y: 0,
		scaleX: 1.1, scaleY: 1.1,
		scrollTrigger: {
			trigger: '#rec585851287',
			start: '-950',
			end: 'top',
			scrub: true
		}
	})

	// Программы обучения - Заголовок
	timeLine.fromTo('.tn-elem__5858564681683023214132', { opacity: 0, y: +200 }, {
		opacity: 1, y: 0,
		scaleX: 1.1, scaleY: 1.1,
		scrollTrigger: {
			trigger: '#rec585856468',
			start: '-950',
			end: 'top',
			scrub: true
		}
	})

	// Программы обучения - Карточки
	let programCard = gsap.utils.toArray('.ucx-program-imeges');
	programCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: -100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: '#rec585856468',
				start: '-950',
				end: 'top',
				scrub: true
			}
		})
	});

	// Для Бизнеса
	let bisenessCard = gsap.utils.toArray('#rec585927917 .t396__elem ');
	bisenessCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-650',
				scrub: true
			}
		})
	});

	// Для Бизнеса
	let partnerCard = gsap.utils.toArray('#rec585952689 .t396__elem ');
	partnerCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-650',
				scrub: true
			}
		})
	});

	// Наша команда
	let teamCard = gsap.utils.toArray('#rec585935894 .t396__elem ');
	teamCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-650',
				scrub: true
			}
		})
	});

	// Этапы сотрудничества
	let stageCard = gsap.utils.toArray('#rec585958198 .t396__elem ');
	stageCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-650',
				scrub: true
			}
		})
	});

	// Оставить заявку
	let callCard = gsap.utils.toArray('#rec585980679 .t396__elem ');
	callCard.forEach(item => {
		timeLine.fromTo(item, { opacity: 0, y: +100 }, {
			opacity: 1, y: 0,
			scrollTrigger: {
				trigger: item,
				start: '-950',
				end: '-650',
				scrub: true
			}
		})
	});

}

$(document).ready(function () {
	/*
	Start script 
	*/

	function appendCode(to, from) { $(to).append($(from)) }

	// Видео на фоне
	appendCode('.bg-video', '#rec589565242');

	// Добавить карту в блок
	appendCode('#map', '#rec586029427')

	/* START Слайдер */
	function SliderOwl(block, wraps, slides, config) {
		this.block = block;
		this.wraps = wraps;
		this.slides = slides;
		this.config = config;
	}

	SliderOwl.prototype.render = function () {
		let block = $(this.block),
			wrap = $(this.wraps),
			slides = $(this.slides),
			box = wrap.find('.tn-atom');

		box.append('<div class="owl-carousel owl-theme"></div>');
		slides.appendTo(block.find('.owl-carousel'));

		let owl = wrap.find('.owl-carousel').owlCarousel(this.config)

		const isW = () => {
			let w;
			w = $(window).width() < 640 ? '93%' : '86%';
			return w;
		}

		slides.each(() => {
			$('.ucx-about-imeges').css({ "max-width": isW() })
			$('.ucx-team-imeges').css({ "max-width": "100%" })
		})

		block.find("[href='#" + this.wraps.replace(/^\./, "") + "_left']").click(function (e) {
			e.preventDefault();
			owl.trigger('prev.owl.carousel');
		})

		block.find("[href='#" + this.wraps.replace(/^\./, "") + "_right']").click(function (e) {
			e.preventDefault();
			owl.trigger('next.owl.carousel');
		})
	};

	AboutSlider = new SliderOwl('#rec586280415', '.about-slider', '#rec586282668, #rec589520184, #rec589523323, #rec589525620', {
		loop: true, center: false, dots: true, nav: false, margin: 0, items: 2, responsive: {
			0: { items: 1.25 },
			640: { items: 1.5 },
			1000: { items: 1 }
		}
	}).render();

	PartnerSlider = new SliderOwl('#rec585952689', '.partner-slider', '#rec590047686, #rec590050258, #rec590050498, #rec590050799, #rec590051025, #rec590051156', {
		loop: true, center: true, autoplay: true, lazyLoad: true, autoPlay: 500, dots: false, nav: false, margin: 20, items: 6, responsive: {
			0: { items: 2, center: false, margin: 20 },
			640: { items: 3, center: false, margin: 30 },
			1000: { items: 6 }
		}
	}).render();

	RewiewSlider = new SliderOwl('#rec586330783', '.review-slider', '#rec586322778, #rec586341716, #rec590123311, #rec590123446', {
		loop: true, center: false, dots: false, nav: false, margin: 0, items: 2, responsive: {
			0: { items: 1.25 },
			640: { items: 1.5 },
			1000: { items: 1 }
		}
	}).render();

	RewiewS2lider = new SliderOwl('#rec590246039', '.review-slider-mob', '#rec591863890, #rec591879695, #rec591880157, #rec591880376', {
		loop: true, center: false, dots: false, nav: false, margin: 10, items: 1, responsive: {
			0: { items: 1 },
			640: { items: 1 },
			1000: { items: 1 }
		}
	}).render();

	TeamSlider = new SliderOwl('#rec589060963', '.team-slider-1', '#rec589062963, #rec589076826, #rec589109009', {
		loop: true, center: false, dots: false, nav: false, margin: 0, items: 2, responsive: {
			0: { items: 1.25 },
			640: { items: 1.5 },
			1000: { items: 1 }
		}
	}).render();

	Team2Slider = new SliderOwl('#rec589112323', '.team-slider-2', '#rec589113760, #rec589113904', {
		loop: true, center: false, dots: false, nav: false, margin: 0, items: 2, responsive: {
			0: { items: 1.25 },
			640: { items: 1.5 },
			1000: { items: 1 }
		}
	}).render();

	/* END Слайдер */


	//Доабвить форму заявки в блок
	$('#form-contacts').append($('#rec586033112').show());
	$('#form-contacts #rec586033112').find('.t-container').removeAttr('class').find('.t-col').removeAttr('class');

	// Программы
	$(function () {
		let content_1 = [];
		let content_2 = [];

		$('.uc-content-program .t772__wrapper .t-card__col ').each(function (i, el) {
			content_1.push({
				title: $(el).find('.t-card__title').text(),
				content_1: $(el).find('.t772__textwrapper').html(),
				btn: $(el).find('.t-card__link').text(),
				link: $(el).find('.t-card__link')[0].href,
				btn_second: $(el).find('.t-card__link_second').text(),
				link_second: $(el).find('.t-card__link_second')[0].hash,
			})
		});

		$('.uc-content-program-1 .t772__wrapper .t-card__col ').each(function (i, el) {
			content_2.push({
				title: $(el).find('.t-card__title').text(),
				content_2: $(el).find('.t772__textwrapper').html(),
				btn: $(el).find('.t-card__link').text(),
				link: $(el).find('.t-card__link')[0].href,
				btn_second: $(el).find('.t-card__link_second').text(),
				link_second: $(el).find('.t-card__link_second')[0].hash,
			})
		});


		$('#rec585856468 .ucx-program-imeges .tn-atom').each(function (i, el) {
			let renderCards = `<div class="program-content">
								<div class="program-content__wrap">
									${content_1[i].content_1}
									<div class="t142A__wrapone">
										<div class="t142A__wraptwo">
											<a class="t142A__btn t-btn t-btn_md t-btn--primary" href="${content_1[i].link}" target="" style="color:#ebe6df;background-color:#39232a;border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;">
												<span>${content_1[i].btn}</span>
											</a>
											<a class="t142A__btn t-btn t-btn_md t-btn--second" href="${content_1[i].link_second}" target="" style="color:#ebe6df;background-color:#eb675b;border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;" data-courses="${content_1[i].title}">
											<span>${content_1[i].btn_second}</span>
											</a>
										</div>
									</div>
								</div>
							</div>`;
			$(el).append(renderCards);

			// $('.program-content .t-card__descr').after($('.program-content .t-card__uptitle'));
		})

		$('#rec585892065 .ucx-program-imeges .tn-atom').each(function (i, el) {
			let renderCard = `<div class="program-content">
								<div class="program-content__wrap">
									${content_2[i].content_2}
									<div class="t142A__wrapone">
										<div class="t142A__wraptwo">
											<a class="t142A__btn t-btn t-btn_md t-btn--primary" href="${content_2[i].link}" target="" style="color:#ebe6df;background-color:#39232a;border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;">
												<span>${content_2[i].btn}</span>
											</a>
											<a class="t142A__btn t-btn t-btn_md t-btn--second" href="${content_2[i].link_second}" target="" style="color:#ebe6df;background-color:#eb675b;border-radius:10px; -moz-border-radius:10px; -webkit-border-radius:10px;" data-courses="${content_2[i].title}">
												<span>${content_2[i].btn_second}</span>
											</a>
										</div>
									</div>
								</div>
							</div>`;
			$(el).append(renderCard);
		})



		$('.program-content .t-card__uptitle').each(function () {
			$(this).css({ "paddingBottom": "40px" });
			for (let i = 0; i < 2; i++) $(this).insertAfter($(this).next());
		});



		$('.uc-content-program').empty();
		$('.uc-content-program-1').empty();
	})

	// Модальные окна
	$(function () {
		//Полуаем нужные элементы
		let popupBtn = document.querySelectorAll('[href^="#uc-zw"]');
		let popupZero = document.querySelectorAll('div[class*="uc-zw-"]');
		let mainPopup = document.querySelector('.uc-main-popup');

		if (popupBtn.length && popupZero.length && mainPopup) {
			let popupCont = mainPopup.querySelector('.t-popup__container');
			let popupCloseIcon = mainPopup.querySelector('.t-popup__close');

			$('.t-popup__container').removeAttr('style');

			//Очищаем popup и получаем ссылку его вызова
			popupCont.innerHTML = '';
			let popupHook = mainPopup.querySelector('.t-popup').getAttribute('data-tooltip-hook');

			//Создаём элемент со ссылкой вызова popup
			let popupLink = document.createElement('a');
			popupLink.href = popupHook;
			popupLink.className = "bf503-zero";
			mainPopup.appendChild(popupLink);

			//Размечаем наши Zero для привязки к нужной ссылке
			for (let i = 0; i < popupZero.length; i++) {
				popupZero[i].classList.add("popup-zero", "hide-popup-zero");

				//Закрываем окно при клике на вне элементов
				popupZero[i].querySelector('.t396__filter').addEventListener('click', function () { popupCloseIcon.click() });

				//Закрываем окно при клике на кнопку #popup-close
				setTimeout(function () {
					let closeIcon = popupZero[i].querySelector('a[href="#popup-close"]');
					if (closeIcon != null) {
						popupCloseIcon.classList.add("hide-popup-zero");
						closeIcon.addEventListener('click', function (e) {
							popupCloseIcon.click(); e.preventDefault();
						});
					};
				}, 1000);

				//Закрываем окно при отправке формы
				popupZero[i].addEventListener('click', function (e) {
					let type = e.target.getAttribute('type');
					if (type == "submit") {
						setTimeout(function () {
							let form = e.target.closest('.t-form');
							if (form.classList.contains('js-send-form-success')) popupCloseIcon.click();
						}, 1000);
					};
				});

				//Добавляем атрибут в виде ссылки
				let classZeroPopup = popupZero[i].getAttribute('class');
				let classArrZeroPopup = classZeroPopup.split(' ');
				classArrZeroPopup.forEach(function (item, j) {
					if (item.includes('uc-zw-')) {
						popupZero[i].setAttribute('data-zero-popup', item);
						popupZero[i].classList.remove(item);
					};
				});
			};

			//Отслеживаем закрытие окна
			let popupShow = mainPopup.querySelector('.t-popup');
			let observer = new MutationObserver(function (mutations) {
				mutations.forEach(function (mutation) {
					setTimeout(function () {
						popupShow = mainPopup.querySelector('.t-popup');
						if (!popupShow.classList.contains('t-popup_show')) {
							let activeZero = mainPopup.querySelector('.active-popup-zero');

							let frame = activeZero.querySelectorAll('iframe');
							if (frame.length) { frame.forEach(i => i.src = i.src) };

							let video = activeZero.querySelectorAll('video');
							if (video.length) { video.forEach(i => i.pause()) };
						};
					}, 500);
				});
			});
			observer.observe(popupShow, { attributes: true, attributeFilter: ['class'] });

			//Отслеживаем нажатие кнопок для вызова окна
			for (let i = 0; i < popupBtn.length; i++) {

				popupBtn[i].addEventListener('click', function (e) {
					e.preventDefault();
					let link = (this.getAttribute('href')).replace('#', '');
					let courses = (this.getAttribute('data-courses'));
					let popupZeroActive = document.querySelector('div[data-zero-popup="' + link + '"]');
					if (popupZeroActive) {
						if (!popupZeroActive.hasAttribute("zero-popup-mode")) {
							popupCont.append(popupZeroActive);
							popupZeroActive.setAttribute('zero-popup-mode', '');
						};
						let zeroInside = popupCont.querySelectorAll('.popup-zero');
						if (zeroInside) {
							zeroInside.forEach(i => i.classList.add("hide-popup-zero"));
							zeroInside.forEach(i => i.classList.remove("active-popup-zero"))
						};
						popupZeroActive.classList.remove('hide-popup-zero');
						popupZeroActive.classList.add('active-popup-zero');

						let popup = mainPopup.querySelector('.t-popup');
						popup.classList.add("popup-transition");
						popupLink.click();
						courses && $('input[name="courses"]').val(courses);
						$('input[name="url"]').val(window.location.href);
					};
				});
			};
		};


	})

	// мобильное меню
	$(function () {
		let navZero = '#rec589671426';
		let openNav = "a[href=#open-nav]";
		$("#allrecords").append('<div class="anku-nav-overlay anku-close-nav"></div>'), $("#allrecords").append('<div class="anku-nav-wrapper"></div>'), $(navZero).addClass("zero-nav-layer").appendTo(".anku-nav-wrapper");
		let closeNav = $(".anku-close-nav, a[href=#close-nav]"), closeLink = $("[href='/#about'],[href='/#program'],[href='/#team'],[href='/#stage'],[href='/#contacts'], [href='#uc-zw-contacts'] ");
		$(openNav).click(function (a) {
			a.preventDefault();
			$("body").addClass("anku-nav-is-open"), setTimeout(function () { t_lazyload_update() }, 50);
		}),
			$(closeNav).click(function (a) { a.preventDefault(), $("body").removeClass("anku-nav-is-open") }),
			$(closeLink).click(function (a) { a.preventDefault(), $("body").removeClass("anku-nav-is-open") });


	})

	// Плавный скролл до якоря
	$(function () {
		$("[href='#about'],[href='#program'],[href='#team'],[href='#stage'],[href='#contacts']").addClass("nextblock t-cover__arrow-wrapper_animated");

		$('.nextblock').click(function () {
			let currentLink = $.attr(this, 'href').substring(1, $.attr(this, 'href').length),
				speed = 1000,
				customOffset = 30,
				top = $('[name="' + currentLink + '"]').offset().top - customOffset; $('html, body').animate({ scrollTop: top }, speed);

			return false;
		});
	});
});

// $(window).scroll(function () {
// 	var header = $('#t-header'),
// 		scroll = $(window).scrollTop();
// 	if (scroll >= 300) header.addClass('fixed');
// 	else header.removeClass('fixed');
// });
